import React from 'react';

import './style.css';

export default props =>{
    return(
        <div className="circle ">
            
        </div>
    )
}